
/*
Put all theme based custom jquery code here :)  Happy days!
*/



jQuery(function ($) {
    $(document).ready(function() {
        var swi = $('.service-with-image');
        var cic = $('.content-with-image-circle');

        swi.each(function(i){
            if(i===1) // If 2nd item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===3) // If 4th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===5) // If 6th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===7) // If 6th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
            if(i===9) // If 6th item then add the class
                $(swi[i] ).find(".highlight-image").addClass('small-order-1 medium-order-2 large-order-2');
            $(swi[i] ).find(".highlight-content").addClass('small-order-2 medium-order-1 large-order-1');
        });
        cic.each(function(i){
            if(i===1) // If 2nd item then add the class
                $(cic[i] ).find(".highlight-image").css('float', 'right');
                //$(cic[i] ).find(".highlight-content").css('text-align', 'right');
            if(i===3) // If 4th item then add the class
                $(cic[i] ).find(".highlight-image").css('float', 'right');
                //$(cic[i] ).find(".background-content").addClass('order-md-1');
            if(i===5) // If 6th item then add the class
                $(cic[i] ).find(".highlight-image").css('float', 'right');
                //$(cic[i] ).find(".background-content").addClass('order-md-1');
            if(i===7) // If 6th item then add the class
                $(cic[i] ).find(".highlight-image").css('float', 'right');
            //$(cic[i] ).find(".background-content").addClass('order-md-1');
            if(i===9) // If 6th item then add the class
                $(cic[i] ).find(".highlight-image").css('float', 'right');
            //$(cic[i] ).find(".background-content").addClass('order-md-1');
        });
            $('img.svg').each(function(){
                var $img = $(this);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if(typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg rocket-lazyload');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, else we gonna set it if we can.
                    if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);
                }, 'xml');
            });

        $('input').each(function() {
            $(this).on('focus', function() {
                $(this).parent().parent('.gfield').addClass('active');
            });
            $(this).on('blur', function() {
                if ($(this).val().length === 0) {
                    $(this).parent().parent('.gfield').removeClass('active');
                }
            });
            if ($(this).val() != '') $(this).parent('.css').addClass('active');
        });
        // Gravity Form Complex Address Component
        $('input').each(function() {
            $(this).on('focus', function() {
                $(this).parent('span').addClass('active');
            });
            $(this).on('blur', function() {
                if ($(this).val().length === 0) {
                    $(this).parent('span').removeClass('active');
                }
            });
            if ($(this).val() != '') $(this).parent('.css').addClass('active');
        });
        //average everyday textareas
        $('textarea').each(function() {
            $(this).on('focus', function() {
                $(this).parent().parent('.gfield').addClass('active');
            });
            $(this).on('blur', function() {
                if ($(this).val().length === 0) {
                    $(this).parent().parent('.gfield').removeClass('active');
                }
            });
            if ($(this).val() != '') $(this).parent('.css').addClass('active');
        });

        $("select").change(function(){
            if($(this).val() !="")
            {
                // logic goes here
                $(this).parent('span').addClass('active');
                $(this).parent().parent('.gfield').addClass('active');
            } else {
                // no option is selected
                $(this).parent('span').removeClass('active');
                $(this).parent().parent('.gfield').removeClass('active');
            }
        });

        $.Scrollax();

        var styles = ["left", "right", "left", "right", "left", "right", "left", "right"];
        var index = 0;
        $("body").find(".service-with-image ").each(function() {
            $(this).addClass(styles[index++]);
            if (index >= styles.length) index = 0;
        });

        $("#tnail-gallery").owlCarousel({

            // Most important owl features
            items : 5,
            itemsDesktop : [1199,5],
            itemsDesktopSmall : [980,4],
            itemsTablet: [768,2],
            itemsTabletSmall: false,
            itemsMobile : [479,1],
            singleItem : false,

            //Basic Speeds
            slideSpeed : 200,
            paginationSpeed : 800,
            rewindSpeed : 1000,

            //Autoplay
            autoPlay : true,
            stopOnHover : true,

            // Navigation
            navigation : true,
            navigationText : ["<i class='fad fa-chevron-left'></i>","<i class='fad fa-chevron-right'></i>"],
            rewindNav : true,
            scrollPerPage : false,

            //Pagination
            pagination : false,
            paginationNumbers: false,

            // Responsive
            responsive: true,
            responsiveRefreshRate : 200,
            responsiveBaseWidth: window,

            // CSS Styles
            baseClass : "owl-carousel",
            theme : "owl-theme",

            //Lazy load
            lazyLoad : true,
            lazyFollow : true,

            //Auto height
            autoHeight : false,

            //Mouse Events
            mouseDrag : true,
            touchDrag : true,

            //Transitions
            transitionStyle : false,

            // Other
            addClassActive : true,

            //Callbacks
            beforeInit: false,
            afterInit: false,
            beforeMove: false,
            afterMove: false,
            afterAction: false,
            startDragging : false
        });


    });
});
